import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Alert,
  CardTitle,
  Form,
  Container,
  Label,
  Input,
  FormGroup,
  Button
} from "reactstrap" 
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import './../../kolodenTheme/css/riskfeed.css'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb" 
import imgStore from './img-1.png';


const RiskFeed = props => {
  const [activeTab, setActiveTab] = useState(0); 


  const [activeIssuesNum, setActiveIssuesNum] = useState('');
  const [ignoredIssuesNum, setIgnoredIssuesNum] = useState('');
  const [activeIssues, setActiveIssues] = useState([]);
  const [ignoredIssues, setIgnoredIssues] = useState([]);
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [selectedCheckboxIgnoreAll, setSelectedCheckboxIgnoreAll] = useState([]);


  const updateIgnoreProductAlert = async (prID, e) => {
    var checkedId = prID;
    var prIgnore = e.target.checked;
    console.log('ID: ' + prID);
    console.log('Ignore: ' + prIgnore);

    if(prIgnore){
      setSelectedCheckboxIds( selectedCheckboxIds => [...selectedCheckboxIds, checkedId]);
    }else{
      setSelectedCheckboxIds( selectedCheckboxIds => selectedCheckboxIds.filter(id => id !== checkedId));
    }

    var ignore = 0;
    if(prIgnore) { ignore = 1; }
    post(url.USER_STOREPRODUCTS_IGNORE_ALERT + '/' + prID + '/' + ignore).then(res => {
      if(res) {      
        console.log(res);
        window.location.reload();
      }
    })
    .catch(error => {
      console.log(error);
    });
  }


  const updateIgnoreAllProductAlert = async (e) => {
    var checkedId = e.currentTarget.dataset.id;
    var type = e.currentTarget.dataset.type;
    var trademark = e.currentTarget.dataset.trademark;
    var registration = e.currentTarget.dataset.registration;
    var goodsServices = e.currentTarget.dataset.goods_services;

    //console.log('checkedId :' + checkedId);

    var ignore = 0;
    var prIgnore = e.target.checked;
    if(prIgnore) { ignore = 1; }

    if(prIgnore){
      setSelectedCheckboxIgnoreAll( selectedCheckboxIgnoreAll => [...selectedCheckboxIgnoreAll, checkedId]);
    }else{
      setSelectedCheckboxIgnoreAll( selectedCheckboxIgnoreAll => selectedCheckboxIgnoreAll.filter(id => id !== checkedId));
    }

    var el = document.querySelectorAll("input[type=checkbox]");
    for (var i=0; i < el.length; i++) {
      var plID = el[i].dataset.pl;
      var prID = el[i].dataset.id;
      var sType = el[i].dataset.type;
      var sTrademark = el[i].dataset.trademark;
      var sRegistration = el[i].dataset.registration;
      var sGoodsServices = el[i].dataset.goods_services;

      //console.log('plID :' + plID);
      //console.log('prID :' + prID);
      /*if (type == sType && trademark == sTrademark && registration == sRegistration && goodsServices == sGoodsServices) {*/
      if(checkedId == plID){

        if(prIgnore){
          el[i].checked = true;
        }else{
          el[i].checked = false;
        }

        //console.log('checkedId :' + checkedId + ' plID: ' + plID + ' prID: ' + prID);


        post(url.USER_STOREPRODUCTS_IGNORE_ALERT + '/' + prID + '/' + ignore).then(res => {
          if(res) {      
            console.log(res);
          }
        })
        .catch(error => {
          console.log(error);
        });
      }

      if(i >= (el.length - 1)){
        setTimeout(function() {
          window.location.reload();
        }, 2000);
      }
    }
  }

   
  const toggleNested = (event) => {
    let dataNestId = event.currentTarget.dataset.id;
    let classChecker = document.querySelector('.line-detail-'+dataNestId).classList;
    
    if(classChecker.contains("show-expand")){
      document.querySelector('.line-detail-'+dataNestId).classList.remove('show-expand');
      event.currentTarget.childNodes[0].classList.add('expand');
      event.currentTarget.childNodes[0].classList.remove('shrink'); 
      
    } else {
      document.querySelector('.line-detail-'+dataNestId).classList.add('show-expand');
      event.currentTarget.childNodes[0].classList.remove('expand');
      event.currentTarget.childNodes[0].classList.add('shrink'); 
    } 
  };

  const copyToClipBoard = (event) => {  
    console.clear(); 
 
    var copyText = event.target.innerHTML;  
    navigator.clipboard.writeText(copyText);
    
    let tinyAlert = document.createElement('div');
    tinyAlert.classList.add('tinyAlert');
    tinyAlert.innerHTML = "URL copied to the Clipboard: " + copyText;
    document.body.appendChild(tinyAlert);

    setTimeout(()=>{
      document.querySelector('.tinyAlert').remove();
    },3000)
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function capitalAllFirst(string){
    return string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  function getFirst2Words(string){
    return string.split(' ').slice(0,2).join(' ');
  }
  const testTheListing = (title, category) => {
    localStorage.setItem('test-title', capitalAllFirst(title));
    localStorage.setItem('test-category', capitalAllFirst(category).split(',')[0]);
    window.location.href = '/tester';
  }



  const allRiskFeedSeen = async () => {
    var notSeen = localStorage.getItem('risk-feed-found');
    if(notSeen > 0){
      post(url.URL_RISK_FEED_ALL_SEEN).then(res => {
        if(res) {
          console.log(res);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  /***** Page Data - Start *****/
  const getPageData = async () => {
    localStorage.setItem('test-title', '');
    localStorage.setItem('test-category', '');

    post(url.URL_RISK_FEED) 
    .then(res => {
      if(res) {
          var num = 0;

          // Active
          num = 0;
          if(res.data[0]){
            setActiveIssues(res.data[0]);
            res.data[0].map( d => {              
              d['product_list'].map( pl => {
                num = num + 1;
              });
            })
          }
          setActiveIssuesNum(num);


          // Ignore
          num = 0;
          if(res.data[1]){
            setIgnoredIssues(res.data[1]);
            res.data[1].map( d => {
              d['product'].map( (p,Pindex) => {
                setSelectedCheckboxIds( selectedCheckboxIds => [...selectedCheckboxIds, p.pr_id]);
              });

              d['product_list'].map( pl => {
                num = num + 1;
                

                var ignoreAll = true;
                d['product'].map( (p,Pindex) => {
                  if(p.type == pl.type && p.trademark == pl.trademark && p.registration == pl.registration && p.goods_services == pl.goods_services){
                    if(pl.ignore == 0){
                      ignoreAll = false;
                    }
                  }
                });

                if(ignoreAll){
                  setSelectedCheckboxIgnoreAll( selectedCheckboxIgnoreAll => [...selectedCheckboxIgnoreAll, pl.id] );
                }

              });
            })
            setIgnoredIssuesNum(num);
          }
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  useEffect(() => {
    getPageData()
    allRiskFeedSeen()
  }, [])


    return (
      <React.Fragment>
        <div className="page-content riskfeed m-0 p-0">
          <Container fluid={true} className=" m-0 p-0">
            {/* <Breadcrumbs title="Menu" breadcrumbItem="Risk Feed" /> */}
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody> 
                    <Row>
                      <Col lg={12} className="mt"> 
                        <div className="tabs-riskfeed">
                          
                          <ul>
                            <li className={activeTab == 0 ? "active" : ""} onClick={()=>{setActiveTab(0)}}><span className="key-prompt">{activeIssuesNum}</span>Active Issues</li>
                            <li className={activeTab == 1 ? "active" : ""} onClick={()=>{setActiveTab(1)}}><span className="key-prompt">{ignoredIssuesNum}</span>Ignored Issues</li>
                          </ul>

                          <div className="tab-panel">
                            { activeTab == 0 ? <> 
                              <div className="table-data-item"> 
                              
                                <table className="table table-light">
                                  <thead>
                                    <tr>
                                        <th scope="col">Word Mark Matches</th>
                                        <th scope="col">U.S. Reg. No.	</th>
                                        <th scope="col" className="col-3">Cited Reference Image</th>
                                        <th scope="col">Goods & Services</th>
                                        <th scope="col">Risk</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* Parent Table is this */}
                                    {activeIssues && activeIssues.map((d,j) => {
                                      return(
                                        <>
                                          {d['product_list'].map( (pl,i) => {
                                            return(
                                              <>
                                                <tr className={ pl['seen'] == 0 ? "line-id highlighted" : "line-id" } key={i}>
                                                  <td key={i}>
                                                    <b>{pl['match_type'] + pl['trademark']}</b>
                                                    {/* The Data-ID should be dynamic and be same as used in nested table id */}
                                                    <div className="lock-expand" onClick={toggleNested} data-id={pl['id']}>
                                                      <span className="expand"> 
                                                      </span> 
                                                        <p>Affected Listings</p>
                                                    </div> 
                                                  
                                                  </td>
                                                  <td key={i}><a href="">{pl["registration"]}</a></td>
                                                  <td key={i}>
                                                    <img src={"http://tsdr.uspto.gov/img/" + pl["serial"] + "/large"}/>
                                                  </td>
                                                  <td key={i}>{pl["goods_services"]}</td>
                                                  <td key={i}>{pl["reason"]}</td>
                                                  <td>
                                                    <label className="container-kol-chkb">Ignore All
                                                      {/*<input type="checkbox" value={pl.id} checked={selectedCheckboxIds.includes(pl.id)} onChange={ e => { updateIgnoreProductAlert(pl.id, e) }}></input>*/}
                                                      <input type="checkbox" data-id={pl["id"]} data-type={pl["type"]} data-trademark={pl["trademark"]} data-registration={pl["registration"]} data-goods_services={pl["goods_services"]} onChange={ e => { updateIgnoreAllProductAlert(e) }}></input>
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                </tr>
                                                

                                                <tr className={"line-detail-" + pl['id'] + " nested-table-area " + (pl['seen'] == 0 ? "highlighted" : "") }>
                                                  <td colSpan={6}>
                                                    <table className="listed-table text-center">
                                                      <thead>
                                                        <th scope="col">Platform / Store</th>
                                                        <th scope="col">Listing Title</th>
                                                        <th scope="col">Main Image</th>
                                                        {/* <th scope="col">URL</th> */}
                                                        <th scope="col">Action</th>
                                                      </thead>
                                                      <tbody>
                                                        {/* Nested Table of above table id*/}
                                                        {/* the number after line-detail- in classname is dynamic and same as above one */}
                                                        {d['product'].map( (p,Pindex) => {
                                                            if(p['type'] == pl['type'] && p['trademark'] == pl['trademark'] && p['registration'] == pl['registration'] && p['goods_services'] == pl['goods_services']){
                                                            return(
                                                              <tr key={Pindex}>
                                                                <td>{p["store_type"] + ' / ' + p["store_name"]}</td>
                                                                <td>{p["title"]}2021 Live Stock</td>
                                                                <td><img className="sub-img" src={p["main_image"]}></img></td>
                                                                {/*<td>
                                                                  <span className="linklist" onClick={(e) => {copyToClipBoard(e)}}>{p[""]}</span>
                                                                </td> */}
                                                                <td className="text-left">
                                                                  <label className="container-kol-chkb">Ignore
                                                                    <input type="checkbox" data-pl={pl["id"]} data-id={p.pr_id} data-type={p["type"]} data-trademark={p["trademark"]} data-registration={p["registration"]} data-goods_services={p["goods_services"]} value={p.pr_id} checked={selectedCheckboxIds.includes(p.pr_id)} onChange={ e => { updateIgnoreProductAlert(p.pr_id, e) }}></input>
                                                                    <span className="checkmark"></span>
                                                                  </label>
                                                                  <button className="test" onClick={(e) => { testTheListing(p["title"], pl["goods_services"]) } }>Test</button>
                                                                </td>
                                                              </tr> 
                                                            )}
                                                          })}  
                                                        
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                          
                                        </>
                                      )
                                    })}
 

                                  </tbody>
                                </table>

                              </div>
                            </> : <>
                              <div className="li-gen-2">
                                <div className="table-data-item"> 
                                
                                  <table className="table table-light">
                                    <thead>
                                      <tr>
                                          <th scope="col">Word Mark Matches</th>
                                          <th scope="col">U.S. Reg. No.	</th>
                                          <th scope="col" className="col-3">Cited Reference Image</th>
                                          <th scope="col">Goods & Services</th>
                                          <th scope="col">Risk</th>
                                          <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      

                                      {/* Parent Table is this */}
                                    {ignoredIssues && ignoredIssues.map((d,j) => {
                                      return(
                                        <>
                                          {d['product_list'].map( (pl,i) => {
                                            return(
                                              <>
                                                <tr className="line-id" key={i}>
                                                  <td key={i}>
                                                    <b>{pl['match_type'] + pl['trademark']}</b>
                                                    {/* The Data-ID should be dynamic and be same as used in nested table id */}
                                                    <div className="lock-expand" onClick={toggleNested} data-id={pl['id']}>
                                                      <span className="expand"> 
                                                      </span> 
                                                        <p>Affected Listings</p>
                                                    </div> 
                                                  
                                                  </td>
                                                  <td key={i}><a href="">{pl["registration"]}</a></td>
                                                  <td key={i}>
                                                    <img src={"http://tsdr.uspto.gov/img/" + pl["serial"] + "/large"}/>
                                                  </td>
                                                  <td key={i}>{pl["goods_services"]}</td>
                                                  <td key={i}>{pl["reason"]}</td>
                                                  <td>
                                                    <label className="container-kol-chkb">Ignore All
                                                      {/*<input type="checkbox" value={pl.id} checked={selectedCheckboxIds.includes(pl.id)} onChange={ e => { updateIgnoreProductAlert(pl.id, e) }}></input>*/}
                                                      <input type="checkbox" data-id={pl["id"]} data-type={pl["type"]} data-trademark={pl["trademark"]} data-registration={pl["registration"]} data-goods_services={pl["goods_services"]} checked={selectedCheckboxIgnoreAll.includes(pl.id)} onChange={ e => { updateIgnoreAllProductAlert(e) }}></input>
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                </tr>
                                                
                                      
                                                <tr className={"line-detail-" + pl['id'] + " nested-table-area"}>
                                                  <td colSpan={6}>
                                                    <table className="listed-table text-center">
                                                      <thead>
                                                        <th scope="col">Platform / Store</th>
                                                        <th scope="col">Listing Title</th>
                                                        <th scope="col">Main Image</th>
                                                        {/* <th scope="col">URL</th> */}
                                                        <th scope="col">Action</th>
                                                      </thead>
                                                      <tbody>
                                                        {/* Nested Table of above table id*/}
                                                        {/* the number after line-detail- in classname is dynamic and same as above one */}
                                                        {d['product'].map( (p,Pindex) => {
                                                            if(p['type'] == pl['type'] && p['trademark'] == pl['trademark'] && p['registration'] == pl['registration'] && p['goods_services'] == pl['goods_services']){
                                                            return(
                                                              <tr key={Pindex}>
                                                                <td>{p["store_type"] + ' / ' + p["store_name"]}</td>
                                                                <td>{p["title"]}2021 Live Stock</td>
                                                                <td><img className="sub-img" src={p["main_image"]}></img></td>
                                                                {/* <td>
                                                                  <span className="linklist" onClick={(e) => {copyToClipBoard(e)}}>{p[""]}</span>
                                                                </td> */}
                                                                <td className="text-left">
                                                                  <label className="container-kol-chkb">Ignore
                                                                    <input type="checkbox" data-pl={pl["id"]} data-id={p.pr_id} data-type={p["type"]} data-trademark={p["trademark"]} data-registration={p["registration"]} data-goods_services={p["goods_services"]} value={p.pr_id} checked={selectedCheckboxIds.includes(p.pr_id)} onChange={ e => { updateIgnoreProductAlert(p.pr_id, e) }}></input>
                                                                    <span className="checkmark"></span>
                                                                  </label>
                                                                  <button className="test" onClick={(e) => { testTheListing(p["title"], pl["goods_services"]) } }>Test</button>
                                                                </td>
                                                              </tr> 
                                                            )}
                                                          })}  
                                                        
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                          
                                        </>
                                      )
                                    })}
  

                                    </tbody>
                                  </table>

                                </div>
                              </div>
                            </> }
                          </div>

                        </div>
                        

                      </Col>
                      
                    </Row> 

                    </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }

export default RiskFeed